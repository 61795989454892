<template>
  <div
    ref="el"
    class="flex flex-col items-center content-center w-full bg-white p-md pb-xl"
  >
    <div class="flex justify-center w-full pb-md">
      <p>
        {{ t('returns.choiceText') }}
      </p>
    </div>
    <div class="flex flex-col justify-between w-full md:flex-row">
      <ReturnClaimTile
        type="return"
        :title="t('returns.returnRequest.title')"
        :text="t('returns.returnRequest.text')"
        :link-text="t('returns.returnRequest.link')"
        link-url="/account/returns/checkout/items"
      />
      <ReturnClaimTile
        type="claim"
        :title="t('returns.claimRequest.title')"
        :text="t('returns.claimRequest.text')"
        :link-text="t('returns.claimRequest.link')"
        link-url="/account/returns/complaint/form"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import ReturnClaimTile from './returnClaimTile.vue';
import { useDialogStore } from '@/stores/useDialogStore';

const { t } = useTrans();

const props = defineProps({
  headline: {
    type: String,
    default: '',
  },
  subHeadline: {
    type: String,
    default: '',
  },
  cta: {
    type: String,
    default: '',
  },

  image: {
    type: String,
    default: '',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  hasClose: {
    type: Boolean,
    default: false,
  },
});

defineEmits<{
  (e: 'clickCta'): void;
  (e: 'close'): void;
}>();

const dialogStore = useDialogStore();
dialogStore.setHeadline(props.headline);
</script>
