<template>
  <Dialog class="w-[750px]">
    <ChoiceReturnsClaim @close="() => dialogStore.closeDialog()" />
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/global/dialogFrame.vue';
import ChoiceReturnsClaim from './components/choiceReturnsClaim.vue';
import { useDialogStore } from '~/stores/useDialogStore';
const dialogStore = useDialogStore();
</script>
